
import React, { useState, useEffect } from 'react';
import { Helmet } from "react-helmet"
import Img from "gatsby-image"

// not sure how to include the sw
// import * as serviceWorker from '../serviceWorker';
import '../index.css';
import '../App.css';

const importBlock = () => import("react-particles-js").then(
  component => component.default
)

// React.lazy(() => import("react-particles-js"));

function Menu() {
  return (
    <div className="menu"> 
      <div>
        <a className="link menuItem" href="#" onClick={(e) => {
          e.preventDefault(); document.querySelector('.welcomeSection').scrollIntoView({behavior:'smooth'})}}>
          Home
        </a>
        <a className="link menuItem" href="#" onClick={(e) => {
          e.preventDefault(); document.querySelector('.angebotSection').scrollIntoView({behavior:'smooth'})}}>
          Angebot
        </a>
        <a className="link menuItem" href="#" onClick={(e) => {
          e.preventDefault(); document.querySelector('.teamSection').scrollIntoView({behavior:'smooth'})}}>
          Kontakt
        </a>
        <a className="link menuItem" href="#" onClick={(e) => {
          e.preventDefault(); document.querySelector('.referencesSection').scrollIntoView({behavior:'smooth'})}}>
          Referenzen
        </a>
      </div>
    </div>
  );
}

function WelcomeSection({data, component}) {
  // particlesJS.load('particles-js', 'assets/particles.json', function() {
  //   console.log('callback - particles.js config loaded');
  // });

  return (
      <header className="welcomeSection">
        {component.length > 0 ? component.map(Particles => (
          <Particles 
            key="particles"
            style={{
              position: 'absolute', 
              width: '100vw', 
              height: '100vh', 
              top: 0, 
              left: 0, 
              zIndex: -1,
              backgroundColor: '#181C1E',
            }} 
            params={{particles: {number: {value: 300}, line_linked: {enable: false}}}}
            /> 
        )) : 
          <div 
            key="placeholder"
            style={{
            position: 'absolute', 
            width: '100vw', 
            height: '100vh', 
            top: 0, 
            left: 0, 
            zIndex: -1,
            backgroundColor: '#181C1E',
          }} />
        }
        <div className="logo">
          <Img 
            style={{ margin: '1rem', maxHeight: 'calc(50vh - 4rem)' }}
            key={data.Logo.childImageSharp.fluid.src}
            imgStyle={{ objectFit: 'contain' }}
            fluid={data.Logo.childImageSharp.fluid} 
            alt="logo" 
            />
        </div>
        <p> Apps, Webseiten und künstliche Intelligenz.</p>
        <div style={{height: '10vh'}}> </div>
      </header>
  );
}

function AngebotSection () {
  return (
    <section className="angebotSection">
      <h1>
        Was wir anbieten
      </h1>
      <p>
        Die Möglichkeiten sind unbegrenzt.
      </p>
      <div style={{textAlign: 'center'}}>
        <div style={{maxWidth: 700, margin: '0 auto'}}>
          <p>
            Schlaue Lösungen bilden das Herzstück der Milliways GmbH. Unser Ziel ist es, pragmatische Lösungen für nahezu jedes IT Problem zu finden. Dafür entwickeln wir Apps, Websiten, Datenbanksysteme und Anwendungen von künstlicher Intelligenz.
          </p>
        </div>
      </div>

    </section>
  );
}

function ReferencesSection() {
  return (
    <section className="referencesSection">
      <h1>Referenzen</h1>
      <div style={{textAlign: 'left'}}>
        <div style={{maxWidth: 900, margin: '0 auto'}}>
          <ul>
            <li>
              <a className="blackLink" target="_blank" href="https://covidtracker.ch">covidtracker.ch</a> <br />
              In Kollaboration mit dem Kanton Bern - eine Plattform um die Ausbreitung des Coronavirus zu verfolgen.
            </li>
            <li>
              <a className="blackLink" target="_blank" href="https://huggenbergermonitor.com">huggenbergermonitor.com</a> <br />
              Eine Platform für Bauprojekte mit live-monitoring und SMS-Alarmdienste.
            </li>
            <li>
              <a className="blackLink" target="_blank" href="https://lucnat.github.io/freedive">Freedive App</a> <br />
              Eine App, mit der man länger die Luft anhält (für Freitaucher, ein trendiger Sport) iOS und Android
            </li>
            <li>
              <a className="blackLink" target="_blank" href="https://www.friedlipartner.ch/ueber-uns/grenzwerte-ch/">Grenzwerte.CH App</a> <br />
              Eine Nachschlage-App für Schadstoffgrenzwerte für unterwegs, iOS und Android
            </li>
            <li>
              <a className="blackLink" target="_blank" href="https://posture-penguin.web.app/">Posture Penguin</a> <br />
              Ein Prototyp um die Körperhaltung vor dem Bildschirm mittels KI zu detektieren
            </li>
            <li>
              <a className="blackLink" target="_blank" href="https://brigitaklin.ch/">Brigit Aklin | Schmuck</a> <br />
              Die persönliche Webseite von Brigit Aklin, Goldschmiedin aus Zürich
            </li>

          </ul>
        </div>
      </div>
    </section>
  );
}

function MapSection() {
  return (
    <iframe 
      style={{height: '400px', width: '100%'}} 
      id="gmap_canvas" 
      src="https://maps.google.com/maps?q=Milliways%20GmbH%2C%20Bauhallengasse%205&t=&z=13&ie=UTF8&iwloc=&output=embed" 
      frameBorder="0" 
      scrolling="no" 
      marginHeight="0" 
      marginWidth="0"></iframe>
  );
}

function TeamSection() {
  return (
    <section className="teamSection">
      <h1>Team</h1>
      <div style={{textAlign: 'left'}}>
        <div style={{maxWidth: 800, margin: '0 auto'}}>
          <p>
            <b>Luca Naterop</b>, <i>Allrounder</i>
            <br /><a href="mailto:luca@milliwayszurich.ch">luca@milliwayszurich.ch</a> <br /><br />
            Luca hat Physik an UZH und ETH studiert und nebenbei als Hobby Apps programmiert. Die Vorliebe für das Programmieren hat sich vom Nebenjob zur Hauptbeschäftigung entwickelt. Die analytischen Fähigkeiten von der Physikausbildung sind ihm von grossem Vorteil in der IT.
          </p>
          <p style={{marginTop: 40}}>
            <b>Jonathan Leitner</b>, <i>Allrounder</i> 
            <br /><a href="mailto:jonathan@milliwayszurich.ch">jonathan@milliwayszurich.ch</a> <br /><br />
            Jonathan hat Informatik an der ETH studiert und nebenbei sich praktisch alles andere selbst beigebracht. Falls mal ganz knifflige Probleme anstehen – beispielsweise im Bereich der künstlichen Intelligenz – dann ist ihm seine akademische Ausbildung von offensichtlichem Nutzen.

          </p>
        </div>
      </div>
      <h1>Kontakt</h1>
        <div style={{maxWidth: 800, margin: '0 auto'}}>
          <p>Milliways GmbH, Bauhallengasse 5, 8004 Zürich</p>
          <a href="mailto:contact@milliwayszurich.ch">contact@milliwayszurich.ch</a>
        </div>
        <br />
    </section>
  );
}

function FooterSection({component}) {
  return (
    <div className="footerSection" style={{position: 'relative', display: 'flex', flexDirection: 'column', alignItem: 'center'}}>
      {component.length > 0 ? component.map(Particles => (
          <Particles 
            key="particles"
            style={{
              position: 'absolute', 
              width: '100vw', 
              height: '100vh', 
              top: 0, 
              left: 0, 
              zIndex: -1,
              backgroundColor: '#181C1E',
            }} 
            params={{particles: {number: {value: 30}, line_linked: {enable: false}}}}
            /> 
        )) : 
          <div 
            key="placeholder"
            style={{
            position: 'absolute', 
            width: '100vw', 
            height: '100vh', 
            top: 0, 
            left: 0, 
            zIndex: -1,
            backgroundColor: '#181C1E',
          }} />
        }
      <p> © 2020 by Milliways GmbH</p>
    </div>
  );
}

function App({data}) {

  const [component, setComponent] = useState([])
  useEffect(() => {
    async function loadComponents() {
        const component = await importBlock();
        setComponent(loadedComponents => loadedComponents.concat(component))
    }
    window.setTimeout(loadComponents, 2500);
  }, [])

  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <meta
          name="description"
          content="Milliways Zürich | Apps, Websites and Artificial Intelligence"
        />
        <title>Milliways | Zürich</title>
        <link 
          rel="preload"
          href={"https://fonts.googleapis.com/css?family=Quicksand&display=swap"} 
          as="style"
          onload="this.onload=null;this.rel='stylesheet'"      
          />
      </Helmet>
      <div className="App">
        <Menu />
        <WelcomeSection data={data} component={component} />
        <AngebotSection />
        <TeamSection />
        <MapSection />
        <ReferencesSection />
        <FooterSection component={component} />
      </div>
    </>
  );
}

export default App;

// see this for explanation how to use lazy loaded images:
// https://www.gatsbyjs.com/plugins/gatsby-image/
export const query = graphql`
  query {
    Logo: file(relativePath: { eq: "logo-transparent.png" }) {
      childImageSharp {
        fluid(maxWidth: 336, maxHeight: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
